import React, { FC } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import IconPicker, { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { Base64SVG, EditInput } from '@sistemiv/s-components'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'

type EditCategoryModalProps = {
  category: any
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onUpdate: (field: string, value: any) => void
  icons: IconOption[]
}
const EditCategoryModal: FC<EditCategoryModalProps> = ({ open, setOpen, category, onUpdate, icons }) => {
  const { t } = useTranslation()

  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('CategoryModal.editCategory')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5 space-y-6'>
        <IconPicker
          selected={icons.find((i) => i.base64string === category.icon)}
          setSelected={(value) => onUpdate('icon', value)}
          options={icons}
        >
          <Base64SVG
            base64image={category.icon}
            className='[&>svg]:w-6 [&>svg]:h-6 [&>svg>*]:fill-gray-500 [&>svg>*]:hover:fill-gray-800'
          />
        </IconPicker>
        <EditInput value={category.name} onSave={(value) => onUpdate('name', value)} />
      </div>
    </ModalDialog>
  )
}

export default EditCategoryModal
