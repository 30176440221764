import React, { FC, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import IconPicker, { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { Base64SVG, Button, Input } from '@sistemiv/s-components'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'

type CreateCategoryModalProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onCreate?: (icon: string, name: string) => void
  icons: IconOption[]
}
const CreateCategoryModal: FC<CreateCategoryModalProps> = ({ open, setOpen, onCreate, icons }) => {
  const [selectedIcon, setSleectedIcon] = useState(icons[0])
  const [createLoading, setCreateLoading] = useState(false)
  const [name, setName] = useState('')
  const { t } = useTranslation()

  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('CategoryModal.createCategory')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5 space-y-6'>
        <IconPicker selected={selectedIcon} setSelected={setSleectedIcon} options={icons}>
          <Base64SVG
            base64image={selectedIcon.base64string}
            className='[&>svg]:w-6 [&>svg]:h-6 [&>svg>*]:fill-gray-500 [&>svg>*]:hover:fill-gray-800'
          />
        </IconPicker>
        <Input
          type='text'
          id='name'
          removePencil
          value={name}
          onChange={(e) => setName(e.target.value)}
          mainClasses='!mt-6'
          classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
        />
        <div className='flex items-center justify-end gap-x-3'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
            {t('Common.cancel')}
          </Button>
          <Button
            loading={createLoading}
            disabled={!name.trim()}
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            onClick={() => {
              setCreateLoading(true)
              onCreate?.(selectedIcon.base64string, name)
            }}
          >
            {t('Common.create')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default CreateCategoryModal
