import React, { FC, useState } from 'react'
import classNames from '../../classNames'
import TaskForm from './TaskForm'
// import TaskComments from './TaskComments'
// import { useTaskDetails } from '../../repositories'
import { DiagramPreview, SpinnerIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
export interface FormField {
  id: string
  label: string
  value: any
  type: string
  description?: string
  isReadOnly?: boolean
  remoteOptionsUrl?: any
  options?: SelectOption[]
  validation?: any
  fileInfo?: any
}

export interface SelectOption {
  value: any
  label: string
}

type Tab = {
  key: 'task' | 'diagram'
  value: string
}

const UserTaskDetails: FC<{ diagram?: any; selectedTask: any; setTaskList: any; isTaskLoading: boolean }> = ({
  diagram,
  selectedTask,
  setTaskList,
  isTaskLoading,
}) => {
  const { t } = useTranslation()
  const tabs: Tab[] = [
    { key: 'task', value: t('UserTasks.task') },
    { key: 'diagram', value: t('UserTasks.diagram') },
    //t('UserTasks.comments'),
  ]
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])

  //const { data: task, isLoading: isTaskLoading } = useTaskDetails(selectedId)

  if (isTaskLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className='w-full h-screen px-8 py-4 overflow-auto'>
      <div className='relative flex justify-start gap-x-8 text-slate-600'>
        <div className='absolute bottom-0 w-full h-0.5 z-0 bg-gray-300' />
        {tabs.map((tab, index) => (
          <div
            key={`tab-${index}`}
            onClick={() => setActiveTab(tab)}
            className={classNames(
              'relative z-2 py-3 cursor-pointer border-b-2 border-gray-300',
              activeTab.key === tab.key ? 'border-blue-500 text-blue-500' : '',
            )}
          >
            {tab.value}
          </div>
        ))}
      </div>
      <div className='mt-6 h-auto'>
        {activeTab.key === 'task' ? (
          <TaskForm selectedTask={selectedTask} setTaskList={setTaskList} />
        ) : // ) : activeTab === t('UserTasks.comments') ? (
        //   <TaskComments />
        activeTab.key === 'diagram' ? (
          selectedTask &&
          diagram && (
            <div className='h-full'>
              <DiagramPreview
                diagramXML={diagram.bpmn20Xml}
                highlightClass={'highlight'}
                activities={diagram.currentActivities}
                incidents={[]}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  )
}

export default UserTaskDetails
